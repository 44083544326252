<mat-expansion-panel #panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon aria-hidden="false" aria-label="ARN Project" [class]="panel.expanded ? 'material-icons' :
      'material-icons-outlined'" fontIcon="folder"></mat-icon>
      <code>{{project.projectKey}}</code>
    </mat-panel-title>
    <mat-panel-description>{{project.admin!.context.messages.project.label}}</mat-panel-description>
    <button mat-icon-button [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation();" aria-label="Project menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button [cdkCopyToClipboard]="server && project ? server ? UrlUtil.join(server.admin.context.config.serverUrl,
      project.projectKey): '' : ''" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Copy URL" fontIcon="content_copy"></mat-icon>
        Copy URL
      </button>
      <!--button (click)="export()" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Export project" fontIcon="download"></mat-icon>
        Export
      </button-->
      <button (click)="delete()" mat-menu-item>
        <mat-icon color="warn" aria-hidden="false" aria-label="Delete project" fontIcon="delete"></mat-icon>
        Delete
      </button>
    </mat-menu>
  </mat-expansion-panel-header>
  <arn-config [attr.id]="id + '-config'" [id]="id + '-config'" [admin]="project.admin" [updating]="updatingConfig"
    (updated)="updateConfig($event)"></arn-config>
  <arn-data [attr.id]="id + '-data'" [id]="id  + '-data'" [admin]="dataAdmin"></arn-data>
</mat-expansion-panel>
