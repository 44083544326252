import { ArnError } from '@arianee/arn-types';

/**
 * A client-side ARN error.
 */
export class ArnClientError extends ArnError {
  constructor(message: string, cause?: any) {
    super(message, cause);
  }
}
