import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ArnProjectInfo } from './ArnProjectInfo';
import { ArnServerInfo } from '../ArnServerInfo';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ArnDataAdmin,
  ArnProjectValidConfigurationValue,
} from '@arianee/arn-admin-client';
import { messages } from 'nx/src/utils/ab-testing';
import { UrlUtil } from '@arianee/arn-types';

@Component({
  selector: 'arn-project',
  templateUrl: './arn-project.component.html',
  styleUrls: ['./arn-project.component.scss'],
  viewProviders: [MatExpansionPanel],
})
export class ArnProjectComponent implements OnInit {
  @Input()
  server?: ArnServerInfo;

  @Input()
  project!: ArnProjectInfo;

  @Output()
  deleted = new EventEmitter<string>();

  @ViewChild('panel', { static: true }) panel?: MatExpansionPanel;

  dataAdmin?: ArnDataAdmin;
  updatingConfig = false;
  id?: string;

  constructor(protected snackBar: MatSnackBar) {}

  async ngOnInit() {
    if (this.server) {
      const serverUrl = this.server?.admin.context.config.serverUrl;
      const serverId = serverUrl?.replace(/[/?#:.-]+/g, '');
      const projectKey = this.project.projectKey;
      this.id = `${serverId}-${projectKey}`;
      this.project.admin = this.server.admin.project(projectKey);
      this.dataAdmin = this.project.admin.data();
    }
  }

  async updateConfig(editedConfig: ArnProjectValidConfigurationValue) {
    if (
      this.server &&
      this.project &&
      window.confirm(
        'Update project "' +
          this.project.projectKey +
          '" on server ' +
          this.server.admin.context.config.serverUrl +
          ' with the edited JSON?'
      )
    ) {
      const overwrite = true;
      try {
        await this.project.admin?.set(editedConfig, overwrite);
        this.snackBar.open(
          this.project.admin!.context.messages.project.config.updated,
          'OK',
          {
            duration: 3000,
            panelClass: 'ok',
          }
        );
      } catch (e) {
        this.snackBar.open((e as Error).toString(), 'Dismiss');
      }
    }
  }

  async delete() {
    const projectKey = this.project.projectKey;
    const admin = this.project.admin || this.server?.admin.project(projectKey);
    if (
      admin &&
      this.server &&
      this.project &&
      window.confirm(
        'Delete project "' +
          projectKey +
          '" from server ' +
          this.server.admin.context.config.serverUrl +
          '?'
      )
    ) {
      await this.project.admin?.delete();
      return this._delete(projectKey);
    }
  }

  async _delete(projectKey: string) {
    this.deleted.emit(projectKey);
  }

  export() {
    //NOP
  }

  protected readonly messages = messages;
  protected readonly UrlUtil = UrlUtil;
}
