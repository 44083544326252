import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditorView } from '@codemirror/view';
import { basicSetup } from 'codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { diagnosticCount, linter, lintGutter } from '@codemirror/lint';
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';
import {
  ArnDataAdmin,
  ArnDataReader,
  ArnDataWriter,
  PredefinedFilter,
} from '@arianee/arn-admin-client';
import { ObjectUtil } from '@arianee/arn-types';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

type Data = {
  _id: string;
  projectKey: string;
  [key: string]: string;
};

@Component({
  selector: 'arn-data',
  templateUrl: './arn-data.component.html',
  styleUrls: ['./arn-data.component.scss'],
})
export class ArnDataComponent implements OnInit {
  @Input()
  data?: Data;

  @Input()
  admin?: ArnDataAdmin;

  @Input()
  id?: string;

  @Input()
  updating = false;

  filter = '';
  predefinedFilter = '';
  dataError = '';
  editedData = '';
  jsonOk = false;

  predefinedFilters: PredefinedFilter[] = [];

  protected editorView?: EditorView;

  protected filterFields = true;

  constructor(protected snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.predefinedFilters =
      this.admin?.context.messages.project.data.predefinedFilters || [];
  }

  protected log(...message: any[]) {
    console.log(this.constructor.name, ...message);
  }

  async update() {
    const updatedData = this.stringToData();
    const webDataReader = new (class implements ArnDataReader<Data> {
      async read(): Promise<Data> {
        return updatedData;
      }
    })();
    this.updating = true;
    this.admin
      ?.import(this.filter, webDataReader, true, null)
      .then(() => {
        this.snackBar.open(
          this.admin!.context.messages.project.data.updated,
          'OK',
          {
            duration: 3000,
            panelClass: 'ok',
          }
        );
      })
      .catch((e: Error) => {
        this.snackBar.open((e as Error).toString(), 'Dismiss');
      })
      .finally(() => {
        this.updating = false;
      });
  }

  protected stringToData(): Data {
    const data = this.data;
    if (!data) {
      throw new Error('Should have existing data to update');
    }
    const fullData: Data = JSON.parse(this.getEditedData());
    if (this.filterFields) {
      if (data._id) {
        fullData._id = data._id;
        this.log('stringToData', 'fullData._id', fullData._id);
      } else {
        this.log('stringToData', 'no fullData._id');
      }
    }
    const admin = ObjectUtil.asSet(
      this.admin,
      () => new Error('Admin instance not set')
    );
    fullData.projectKey = admin.projectKey;
    return fullData;
  }

  protected getEditedData(): string {
    if (this.editedData) {
      this.editedData = this.editorView?.state.doc.toString() || '';
    } else {
      this.editedData = this.dataToString();
    }
    return this.editedData;
  }

  protected dataToString() {
    const data = { ...this.data };
    if (this.filterFields) {
      this.log('dataToString', 'data._id', data._id);
      delete data._id;
      this.log('dataToString', 'data.projectKey', data.projectKey);
      delete data.projectKey;
    }
    return JSON.stringify(data, null, 2);
  }

  export() {
    const link = document.createElement('a');
    link.href = `data:text/plain;charset=utf-8,\uFEFF${encodeURIComponent(
      this.getEditedData()
    )}`;
    link.download = this.id + `arn-data.json`;
    link.click();
  }

  protected completion(context: CompletionContext) {
    if (!context) {
      return null;
    }
    let node: any = syntaxTree(context.state).resolveInner(context.pos, -1);
    let str = '';
    while (node !== null) {
      str = (node as any).context.buffer.buffer + '.' + str;
      node = node.parent;
    }
    this.log(str);
    const word = context.matchBefore(/\w*/);
    if (!word || (word.from == word.to && !context.explicit)) {
      return null;
    }
    return {
      from: word.from,
      options: [
        { label: 'match', type: 'keyword' },
        { label: 'hello', type: 'variable', info: '(World)' },
        { label: 'magic', type: 'text', apply: '⠁⭒*.✩.*⭒⠁', detail: 'macro' },
      ],
    };
  }

  protected loadEditor() {
    const editorSelector = `#${this.id} .full-json-editor`;
    const editor = document.querySelector(editorSelector);
    if (this.editorView) {
      this.editorView.destroy();
    }
    const linterExtension = linter(jsonParseLinter());
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.editorView = new EditorView({
      doc: this.editedData,
      extensions: [
        basicSetup,
        lintGutter(),
        autocompletion({ override: [self.completion] }),
        linterExtension,
        json(),
        EditorView.updateListener.of((e) => {
          this.jsonOk = diagnosticCount(e.state) <= 0;
        }),
      ],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parent: editor!,
    });
  }

  async fetch() {
    if (this.admin) {
      this.data = {} as any;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const webDataWriter = new (class implements ArnDataWriter<Data> {
        writeAll(data: any): void {
          // TODO: Was data: Data
          self.data = data;
          self.editedData = self.dataToString();
        }
      })();
      try {
        this.dataError = '';
        await this.admin.export(this.filter, webDataWriter);
      } catch (e) {
        this.dataError = (e as Error).message;
        this.editedData = '';
      } finally {
        this.loadEditor();
      }
    }
  }

  fetchPredefined($event: MatButtonToggleChange) {
    if (this.filter === $event.value) {
      this.filter = '';
    } else {
      this.filter = $event.value;
    }
    this.fetch();
  }
}
