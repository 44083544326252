import { AuthContextPersistStrategy } from './AuthContextPersistStrategy';
import { ArnAuthStatus } from '../ArnAuthStatus';
import {
  ArnConnectorType,
  ArnLogger,
  WALLET_CONNECT_KEY,
} from '@arianee/arn-types';

export class StorageAuthContextPersistStrategy
  implements AuthContextPersistStrategy
{
  protected log: ArnLogger;

  constructor(
    parentLog: ArnLogger,
    readonly storages: Storage[],
    protected prefix: string
  ) {
    this.log = parentLog.fork('StorageAuthContextPersistStrategy');
    this.log.debug('constructor', storages);
  }

  /**
   * An error-resilient method to execute something on storage(s).
   *
   * @param cb The callback to execute.
   */
  protected forEachStorage<R>(cb: (storage: Storage) => R): any {
    for (let i = 0; i < this.storages.length; i++) {
      const storage = this.storages[i];
      try {
        const value = cb(storage);
        if (value) {
          return value;
        }
      } catch (e) {
        this.log.warn('Could not access storage', storage, 'because of', e);
      }
    }
  }

  setStatus(newStatus: ArnAuthStatus): void {
    const log = this.log.fork('saveStatus');
    log.debug('()', newStatus);
    this.forEachStorage((storage) => {
      storage.setItem(
        this.prefix + newStatus.connectorType,
        JSON.stringify(newStatus)
      );
      log.debug('Saved status', newStatus, 'in', storage);
    });
  }

  removeWalletConnect(): void {
    this.forEachStorage((storage) => {
      storage.removeItem(WALLET_CONNECT_KEY);
    });
  }

  getStatus(connectorType: ArnConnectorType): ArnAuthStatus | undefined {
    const authStatusStr: any = this.forEachStorage((storage) => {
      return storage.getItem(this.prefix + connectorType);
    });
    return authStatusStr && authStatusStr !== ''
      ? (JSON.parse(authStatusStr) as ArnAuthStatus)
      : undefined;
  }
}
