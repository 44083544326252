import {
  BrowserProvider,
  TransactionRequest,
  TransactionResponse,
  TypedDataDomain,
  TypedDataField,
} from 'ethers';
import { ArnConnectorOptions } from '../ArnValidAuthConfig';
import EventEmitter from 'eventemitter3';
import { ArnConnectorType } from './ArnConnectorType';

export const WALLET_CONNECT_KEY = 'walletconnect';

export type ArnConnectorStatus = 'disconnected' | 'connecting' | 'connected';

/**
 * @param O connect options.
 */
export interface ArnConnector<
  O extends ArnConnectorOptions = ArnConnectorOptions
> {
  /**
   * Establish a connection with the user's wallet.
   * @return The user's wallet address
   */
  connect(): Promise<string>;

  /**
   * Must be called if the user is automatically from cache at a higher level.
   * This allows the connector to perform some cleanup if needed.
   */
  onConnectFromCache(): Promise<void>;

  /**
   * Request the user to sign a message using his wallet.
   * @param message The message to sign
   * @return The signature of the message (hex string)
   */
  signMessage(message: string): Promise<string>;

  /**
   * Request the user to sign typed data using his wallet.
   * @see https://eips.ethereum.org/EIPS/eip-712
   * @return The signature of the typed data (hex string)
   */
  signTypedData(
    domain: TypedDataDomain,
    types: Record<string, Array<TypedDataField>>,
    value: Record<string, any>
  ): Promise<string>;

  /**
   * Request the user to sign a transaction using his wallet.
   * @param transaction The transaction to sign
   * @return The signed transaction (hex string)
   */
  signTransaction(transaction: TransactionRequest): Promise<string>;

  /**
   * Request the user to send a transaction using his wallet.
   * @param transaction The transaction to send
   * @return The transaction response (not the receipt)
   */
  sendTransaction(
    transaction: TransactionRequest
  ): Promise<TransactionResponse>;

  /**
   * Request the user to send a transaction using his wallet.
   * @param transaction The transaction to send
   * @return The transaction hash (hex string)
   */
  sendUncheckedTransaction(transaction: TransactionRequest): Promise<string>;

  /**
   * @deprecated
   * Return the unsigned message used to authenticate the user.
   * Only implement this method if your connector use a non pre-defined message to authenticate the user otherwise throw an error.
   */
  getAuthMessage(): Promise<string>;

  /**
   * Request the user's wallet address.
   */
  getAddress(): Promise<string | undefined>;

  /**
   * Request the user's chain ID.
   */
  getChainId(): Promise<bigint>;

  /**
   * Request the user to switch to a specific chain.
   * @param chainId The chain ID to switch to
   */
  switchChain(chainId: bigint): Promise<void>;

  /**
   * Disconnect from the user's wallet.
   */
  disconnect(): Promise<void>;

  status: ArnConnectorStatus;

  readonly status$: EventEmitter<ArnConnectorStatus>;

  provider: BrowserProvider | undefined;

  readonly options: O;

  destroy(): Promise<void>;

  type: ArnConnectorType;
}
